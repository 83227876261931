import React from 'react'
import { useState } from 'react'
import { useSnapshot } from 'valtio';
import { DB } from '../../store/store';
import { useParams } from "react-router-dom";
// import resize from "../../assets/images/model/resize.svg";
// import close_icon from "../../assets/images/close_icon.svg"
import info_circle from "../../assets/images/info-circle.svg"

const Accordion = ({ mainData, node, option, imageExpand }) => {
    const [isOpen, setOpen] = useState(false);
    const params = useParams();
    const { label } = params;
    const newLabel = decodeURIComponent(label);
    const finalLabel = newLabel.replace(/_/g, ' ');
    const snap = useSnapshot(DB);

    const handleClick = (index) => {
        mainData.child.map((item, i) => {
            if (i === index) {
                if (item.name === "Fusion Standard Music Package" || item.name === "Fusion Premium Music Package") {

                    const isActive = DB.models[0].nodes[node].options[option].child[index].active;

                    DB.models[0].nodes[node].options[option].child.map((m)=> {
                        if (m.name === "Fusion Standard Music Package" || m.name === "Fusion Premium Music Package") {
                            m.active = false;
                        }
                    })

                    if(!isActive){
                        DB.models[0].nodes[node].options[option].child[index].active = true;
                    }
                } else {
                    const newArr = [...DB.models[0].nodes[node].options[option].child]
                    newArr[index].active = !newArr[index].active;
                }
            }
            return item;
        });
    }

    const getDealerOptions = (label) => {
        const dealer = snap.dealers.find(dealer => dealer.options.some(option => option.label === label));
        return dealer ? dealer.options.find(option => option.label === label) : null;
    }

    const dealerOption = getDealerOptions(finalLabel);

    const isDisabled = (item) => {
        if (!dealerOption) return false;
        if (!dealerOption.additional_logic || !item.additional_logic) return false;

        return dealerOption.additional_logic.includes(item.additional_logic);
    }


    return (
        <div className='accordion'>
            <div>
                <div className={`accordion_header ${isOpen ? "open" : ""}`} onClick={() => setOpen(!isOpen)}>
                    {mainData.name} {isOpen ? <span>-</span> : <span>+</span>}
                </div>

                <div className={`accordion_content ${!isOpen ? "collapsed" : ""}`}>
                    {mainData.child.length ?
                        mainData.child.map((a, i) => (
                            <div key={a.id} className={`d-flex align-items-center justify-content-between mb-2 ${isDisabled(a) ? 'd-none' : ''}`}>
                                <div 
                                    key={a.id} 
                                    className={`confirm_content ${a.active ? 'active' : ''}`}
                                    onClick={() => handleClick(i)}
                                    style={{cursor: 'pointer', width: "80%"}}>
                                    <span className="touches_content" style={{width: "60%"}} title={a.name}>
                                        {a.name}
                                    </span>
                                    <span style={{width: "50%"}} className='acc_popup_block'>
                                        <span className="accordion_add" style={{textAlign: "center", width: "66px", height: "30px", paddingTop: "3px"}}>{a.active ? ' ' : 'Add +' } </span>
                                        
                                    </span>
                                </div>
                                
                                <div style={{width: "20%", textAlign: "right"}}>
                                    {(a.info || a.img_src) ? <span className="image_expansion" onClick={() => imageExpand(option+'_'+i)} >More Info</span> : <span style={{width: "30%"}}> </span>}
                                </div>
                            </div>
                        ))
                        :
                        <li>No options available</li>
                    }
                </div>
            </div>
        </div>
    )
}

export default Accordion