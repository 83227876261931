import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'bootstrap/dist/css/bootstrap.css';
import { DB } from './store/store';
import { useSnapshot } from 'valtio';
import { useParams } from 'react-router-dom';
import Select from 'react-select';
import image from "./assets/images/swiper-img.jpg";
import './Yatcht.scss';

const Yatcht = () => {
    const params = useParams();
    const { brand } = params;
    if(brand !== undefined){
        DB.brand_slug = brand;
    }

    const snap = useSnapshot(DB);
    
    const onChange = (e) => {
        DB.yatcht_label = e.label;
        const t = e.tenders_available;
        DB.yatcht_tenders = [];
        t.map((item, i) => {
            const temp = DB.all_tenders.filter((f) => (f.value === item));
            DB.yatcht_tenders.push(temp);
            return '';
        })
        //console.log(DB.yatcht_tenders);
    }

    return (
        <>
            <div className="content-area row">
                <div className="search-dropdown">
                    <div className="select-block">
                        <h5>
                            Let's find your yacht
                        </h5>
                        
                        <div>
                            <Select
                                name="yacht"
                                options={snap.yachts}
                                onChange={(e) => {
                                    onChange(e);
                                }}
                                placeholder="Start typing your yacht model"
                            />
                        </div>
                    </div>
                </div>

                {(DB.yatcht_label !== '') ? 
                    <div className='slider-wrapper'>

                        <div className="row pb-4">
                            <div className="col-12 text-start">
                                <p className="para-heading m-0">Results</p>
                                <p className="para-content m-0">These are the perfect tenders for your {DB.yatcht_label}.</p>
                                <p className="para-content m-0">Subject to a fitment review</p>
                            </div>
                        </div>

                        <Swiper
                            modules={[Navigation, Pagination]}
                            navigation
                            pagination={{
                                clickable: true,
                                renderBullet: function (index, className) {
                                    return '<span class="px-2 ' + className + '">' + 0 + (index + 1) + "</span>";
                                }
                            }}
                            autoHeight={true}
                            spaceBetween={30}
                            slidesPerView={"auto"}
                            centeredSlides={false}
                            className="video-slider"
                        >
                            {DB.yatcht_tenders.map((item, i) =>(
                                item.map((itm, j) =>{
                                const buttonLink = `${brand ? `/yacht-brand/${brand}` :  ''}/${itm.label.trim().split(/\s+/)[0]}/${itm.label.trim().split(/\s+/)[1]}`;
                                return  (
                                    <SwiperSlide key={i} className="bg">
                                        <div className="slider-image-block">
                                            <img src={itm.yatcht_images.large} alt="img" />

                                            <div className="banner-text-section video-btn">
                                                <h4>{itm.label}</h4>
                                                <div className="banner-buttons desktop-res">
                                                    <a
                                                        key={"button"+i}
                                                        href={buttonLink}
                                                        className="btn btn_white btn_arrow_2"
                                                        onClick={() => {
                                                        DB.API_ready_for_tender = false;
                                                        }}>
                                                            Build My Tender<span></span>
                                                        </a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="slider-content-block">
                                            <div className="content-area">
                                                <span>
                                                    <p className="text-center text-md-start">Seating</p>
                                                    <p className="para-color text-center text-md-end">{itm.td_specification.td_seating}</p>
                                                </span>
                                                <span>
                                                    <p className="text-center text-md-start">LOA</p>
                                                    <p className="para-color text-center text-md-end">{itm.td_specification.td_loa}</p>
                                                </span>
                                                <span>
                                                    <p className="text-center text-md-start">Max Speed</p>
                                                    <p className="para-color text-center text-md-end">{itm.td_specification.td_max_speed}</p>
                                                </span>
                                                <span>
                                                    <p className="text-center text-md-start">Engine</p>
                                                    <p className="para-color text-center text-md-end">{itm.td_specification.td_engine}</p>
                                                </span>
                                                <span>
                                                    <p className="text-center text-md-start">BEAM</p>
                                                    <p className="para-color text-center text-md-end">{itm.td_specification.td_beam}</p>
                                                </span>
                                                <span>
                                                    <p className="text-center text-md-start">Fuel Capacity</p>
                                                    <p className="para-color text-center text-md-end">{itm.td_specification.td_fuel_capacity}</p>
                                                </span>
                                            </div>
                                            <div className="mobile-res">
                                                <div className="banner-buttons">
                                                    <a
                                                        key={"button"+i}
                                                        href={buttonLink}
                                                        className="btn btn_white btn_arrow"
                                                        onClick={() => {
                                                            DB.API_ready_for_tender = false;
                                                        }}
                                                    >
                                                        Tenders<span></span>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </SwiperSlide>);
                                })
                            ))}   
                        </Swiper>
                    </div> 
                    : 
                    <div> </div>
                }
            </div>
        </>        
    )
}
export default Yatcht