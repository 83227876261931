import { Controller, useForm } from "react-hook-form"
import { useState  } from "react";
import Select, { createFilter } from 'react-select';
import { useSnapshot } from "valtio";
import { DB, country } from "./store/store";
import { useParams } from "react-router-dom";
import { postFormData } from "./api/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { usePDF } from '@react-pdf/renderer'; // Document is needed for tender image to work/show in attachment PDF.
import AttachPDF from './ui/pdf/AttachPDF';

const CheckoutForm = () => {
    const params = useParams();
    const { label, brand } = params;
    const snap = useSnapshot(DB);
    const snap2 = useSnapshot(country);
    const [detailsSubmit, setDetailsSubmit] = useState('Submit');
    const [successMsg, setSuccessMsg] = useState(false);
    if(brand !== undefined){
        DB.brand_slug = brand;
    }
    let specs = '';
    let options = '';
    specs += "Tender Name: " + DB.models[0].name + "\n";

    const onSubmit = async (data) => {
            // console.log(data, data.pdf_file)
            setDetailsSubmit('Sending ...');
            let newImg;
            await fetch(DB.emailImg)
            .then(res => res.blob())
            .then(r => {
                console.log(r)
                newImg = r;
            })
    
            // const img = DB.emailImg;
            var formdata = new FormData();
            formdata.append("input_1", data.name);
            formdata.append("input_3", data.email);
            formdata.append("input_4", data.tel);
            formdata.append("input_5", data.country.label + " - " + data.country.value);
            formdata.append("input_6", data.brand_full_name);
            formdata.append("input_7", data.brand_email);
            formdata.append("input_25", data.brand_phone_number);
            formdata.append("input_26", data.yacht_name);
            formdata.append("input_18", specs);
            formdata.append("input_18", options);
            formdata.append("input_21", instance.blob, "WJT.pdf");
            // formdata.append("input_22", img);
            formdata.append("input_23", newImg, "Tender.png");
            formdata.append("input_24", DB.models[0].name);
            formdata.append("input_15", data.checkbox1);
            formdata.append("input_16", data.checkbox2);
            formdata.append("input_27", snap.active_brand_email);
            
            await postFormData(process.env.REACT_APP_API_URL +"gf/v2/forms/9/submissions", formdata).then((data) => {
                // console.log(data); // JSON data parsed by `data.json()` call
                // id = data.id;
               // console.log(data.is_valid);
                if (data.is_valid){
                    console.log('in success');
                    setSuccessMsg(true);
                    // setTimeout(() => {
                    //     setSuccessMsg(false);
                    // }, 6000);
                }else{
                    alert ("Server error, please try after sometime.");
                }
            });
    
            reset();
            setDetailsSubmit('Submit');
    };

    const {
        register,
        formState: { errors },
        handleSubmit,
        reset,
        control
    } = useForm();

const [instance, updateInstance] =  usePDF({ document: <AttachPDF objs={snap} img={snap.emailImg} /> });

return (
        <>
        <div className="details-form yatch-form">
        <div className="max-wrap">
            {successMsg ?
                <div style={{ color: '#6d9b36'}} className="d-flex flex-column">
                    <div><FontAwesomeIcon icon={faCircleInfo} /> <span>Thanks for contacting us! We will get in touch with you shortly.</span></div>
                </div>
                :
                <form key={1} onSubmit={handleSubmit(onSubmit)} id="checkoutform">
                    <div className="row" style={{ borderBottom: '1px solid #DDDCDD' }}>
                        <div className="col-md-12 col-lg-6 pb-5 pb-0">
                            <div>
                                <h4 className="form-title">Your Details</h4>
                            </div>
                            <div>
                                <div className="mb-3">
                                    <input
                                        id="name"
                                        type="text"
                                        className="form-control"
                                        placeholder="Full Name*"
                                        {...register("name", {
                                            required: {
                                                value: true,
                                                message: "Full Name is required"
                                            },
                                        })}
                                    />
                                    <p style={{ color: "red" }}>{errors.name?.message}</p>
                                </div>

                                <div className="mb-3">
                                    <input
                                        type="email"
                                        id="email"
                                        className="form-control"
                                        placeholder="Email Address*"
                                        {...register("email", {
                                            required: {
                                                value: true,
                                                message: "Email is required"
                                            },
                                            pattern: {
                                                value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                                                message: "Invalid email format"
                                            }
                                        })} />
                                    <p style={{ color: "red" }}>{errors.email?.message}</p>
                                </div>

                                <div className="mb-3">
                                    <input
                                        type="tel"
                                        id="tel"
                                        className="form-control"
                                        placeholder="Phone Number"
                                        {...register("tel", {
                                            pattern: {
                                                value: /^[\d\s()+-]+$/,
                                                message: "Invalid phone number format"
                                            }
                                        })}
                                    />
                                    <p style={{ color: "red" }}>{errors.tel?.message}</p>
                                </div>
                                <Controller
                                    control={control}
                                    name="country"
                                    rules={{
                                        required: "Please Select Country.",
                                    }}
                                    defaultValue=""
                                    render={({ field: { onChange, onBlur, value, name, ref } }) => (
                                        <div className="mb-3">
                                            <Select
                                                name={name}
                                                ref={ref}
                                                onChange={(e) => {
                                                    onChange(e);
                                                }}
                                                onBlur={onBlur}
                                                value={value}
                                                options={snap2.country1}
                                                getOptionLabel={(e) => e.label}
                                                getOptionValue={(e) => e.value}
                                                placeholder="Select a Country*"
                                                closeMenuOnSelect={true}
                                                filterOption={createFilter({ matchFrom: "start" })}
                                            />
                                            <div style={{ color: "red" }}>
                                                {errors.country && errors.country.message}
                                            </div>
                                        </div>
                                    )}
                                />

                                <div className="form-checkbox">
                                    <div className="d-flex align-items-start align-items-md-center pb-2">
                                        <input
                                            style={{ verticalAlign: "middle" }}
                                            type="checkbox"
                                            id="exampleCheck1"
                                            value="I am happy for Williams to contact me with a no obligation quote"
                                            {...register("checkbox1")}
                                        />
                                        <label className="form-check-label ps-2" htmlFor="exampleCheck1">I am happy for Williams to contact me with a no obligation quote</label>
                                    </div>

                                    <div className="d-flex align-items-start align-items-md-center">
                                        <input
                                            style={{ verticalAlign: "middle" }}
                                            type="checkbox"
                                            id="exampleCheck2"
                                            value="I am happy to receive further marketing communication from Williams"
                                            {...register("checkbox2")}
                                        />
                                        <label className="form-check-label ps-2" htmlFor="exampleCheck2">I am happy to receive further marketing communication from Williams</label>
                                    </div>
                                    <div style={{ color: "red" }}>
                                        {errors.language && errors.language.message}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-12 col-lg-6">
                                <div>
                                    <h4 className="form-title">Your {DB.brand_contact_name} Contact</h4>
                                </div>
                                <div>
                                    <div className="mb-3">
                                        <input
                                            id="brand_full_name"
                                            type="text"
                                            className="form-control"
                                            placeholder="Full Name*"
                                            {...register("brand_full_name", {
                                                required: {
                                                    value: true,
                                                    message: "Full Name is required"
                                                }
                                            })}
                                        />
                                        <p style={{ color: "red" }}>{errors.brand_full_name?.message}</p>
                                    </div>
                                    <div className="mb-3">
                                        <input
                                            id="brand_email"
                                            type="text"
                                            className="form-control"
                                            placeholder="Email Address*"
                                            {...register("brand_email", {
                                                required: {
                                                    value: true,
                                                    message: "Email is required"
                                                },
                                                pattern: {
                                                    value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                                                    message: "Invalid email format"
                                                }
                                            })}
                                        />
                                        <p style={{ color: "red" }}>{errors.brand_email?.message}</p>
                                    </div>
                                    
                                    <div className="mb-3">
                                    <input
                                        type="tel"
                                        id="brand_phone_number"
                                        className="form-control"
                                        placeholder="Phone Number"
                                        {...register("brand_phone_number", {
                                            pattern: {
                                                value: /^[\d\s()+-]+$/,
                                                message: "Invalid phone number format"
                                            }
                                        })}
                                    />
                                    <p style={{ color: "red" }}>{errors.brand_phone_number?.message}</p>
                                    </div>

                                    <div className="mb-3">
                                        <input
                                            id="yacht_name"
                                            type="text"
                                            className="form-control"
                                            placeholder="Project Reference / Yacht Name"
                                            {...register("yacht_name")}
                                        />
                                    </div>
                                    
                                    <div className="d-inline-block float-lg-end pt-3">
                                        <button type="submit" className="btn btn-primary btn-submit">{detailsSubmit} <span><img src="/images/Checkout/arrow-icon.png" /></span></button>
                                    </div>
                                </div>
                        </div> 
                    </div>
                </form>
            }
            
        </div>
    </div>
    </>
)
}
export default CheckoutForm