import React from 'react'
import ColorPicker from './sub_components/ColorPicker'
import ConfirmBox from './sub_components/ConfirmBox';
import { createContext, useContext } from 'react';

const GelcoatContext = createContext(null);

export const useGelcoat = () => {
    return useContext(GelcoatContext);
};

const Gelcoat = ({ item }) => {

    const mainData = item.nodes.filter((f) => f.node_name === "Gelcoat");
    const colorData = mainData[0].options.filter((f) => f.name === "Choose your helm colour");
    const colorData_2 = mainData[0].options.filter((f) => f.name === "Choose your tube pod colour");
    const colorData_3 = mainData[0].options.filter((f) => f.name === "Choose your deck colour");
    const colorData_4 = mainData[0].options.filter((f) => f.name === "Choose your hull colour");
    const boardingKit = mainData[0].options.filter((f) => f.name === "Would you like to add a boarding kit (Step pad & boarding pole)?");

    return (
        <GelcoatContext.Provider value={mainData}>
            {mainData[0].active &&
                <div className='upholstery'>
                    <h4>{mainData[0].node_name}</h4>
                    {colorData[0].active && <ColorPicker
                        colorData = {colorData}
                        className="gelcoat_picker"
                        node = {3}
                        option = {0} 
                    />}
                    {colorData_3[0].active && <ColorPicker
                        colorData = {colorData_3}
                        className="gelcoat_picker"
                        node = {3}
                        option = {2} 
                    />}
                    {colorData_4[0].active && <ColorPicker
                        colorData = {colorData_4}
                        className="gelcoat_picker"
                        node = {3}
                        option = {3} 
                    />}
                    {boardingKit[0].active && <ConfirmBox
                        logoData = {boardingKit}
                        className = "boarding-picker"
                        node = {3}
                        option = {4}
                    />}
                    {colorData_2[0].active && <ColorPicker
                        colorData = {colorData_2}
                        className="gelcoat_picker_bottom"
                        node = {3}
                        option = {1} 
                    />}
                </div>
            }
        </GelcoatContext.Provider>
    )
}

export default Gelcoat