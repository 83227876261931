import { Controller, useForm } from "react-hook-form"
import { useState, useEffect  } from "react";
import Select, { createFilter } from 'react-select';
import { useSnapshot } from "valtio";
import { DB, country } from "./store/store";
import { postData } from "./api/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";

const CallbackForm = () => {
    const snap = useSnapshot(DB);
    const snap2 = useSnapshot(country);
    const [requestCallback, setRequestCallback] = useState('Request Call Back');
    const [successMsg2, setSuccessMsg2] = useState(false);

    const onSubmitEmail = async (data) => {
            setRequestCallback('Sending ...');
            let id = 0;
            await postData(process.env.REACT_APP_API_URL +"gf/v2/entries", { 
                'form_id': 5, 
                1: data.name1, 
                3:  data.email1, 
                4:  data.tel1, 
                5: data.country1.label + " - " + data.country1.value, 
            }).then((res) => {
                id = res.id;
            });
    
            await postData(process.env.REACT_APP_API_URL +"gf/v2/entries/"+id+"/notifications", { 
                'form_id': 5 
            }).then((res2) => {
                setSuccessMsg2(true);
                // setTimeout(() => {
                //     setSuccessMsg2(false);
                // }, 5000);
            });
    
            reset2();
            setRequestCallback('Request Call Back');
        };

        const {
            register: register2,
            formState: { errors: errors2 },
            handleSubmit: handleSubmit2,
            reset: reset2,
            control: control2
        } = useForm();

return (
        <>
        <div className="details-form details-form-2">
        <div className="max-wrap">
            <div className="row">
                <div className="col-md-12 col-lg-6 pb-5 pb-lg-0">
                    <div>
                        <h4 className="form-title">Request a call back</h4>
                    </div>
                    <div className="pe-lg-5">
                        If you'd prefer to speak to someone about your requirements, simply fill out the form and one of our team will get back to you.
                    </div>
                </div>
                <div className="col-md-12 col-lg-6">
                    <div>
                        <h4 className="form-title">{successMsg2 ? <span>&nbsp;</span> : 'Your Details'}</h4>
                    </div>
                    <div>
                        {successMsg2 ?
                            <div style={{ color: '#6d9b36'}}>
                                <FontAwesomeIcon icon={faCircleInfo} /> <span>Thanks for contacting us! We will get in touch with you shortly.</span>
                            </div>
                            :
                            <form key={2} onSubmit={handleSubmit2(onSubmitEmail)} id="requestcallback">
                                <div className="mb-3">
                                    <input
                                        id="name1"
                                        type="text"
                                        className="form-control"
                                        placeholder="Full Name*"
                                        {...register2("name1", {
                                            required: {
                                                value: true,
                                                message: "Full Name is required"
                                            },
                                        })} />
                                    <p style={{ color: "red" }}>{errors2.name1?.message}</p>
                                </div>

                                <div className="mb-3">
                                    <input
                                        type="email"
                                        id="email1"
                                        className="form-control"
                                        placeholder="Email Address*"
                                        {...register2("email1", {
                                            required: {
                                                value: true,
                                                message: "Email is required"
                                            },
                                            pattern: {
                                                value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                                                message: "Invalid email format"
                                            }
                                        })} />
                                    <p style={{ color: "red" }}>{errors2.email1?.message}</p>
                                </div>

                                <div className="mb-3">
                                    <input
                                        type="tel"
                                        id="tel1"
                                        className="form-control"
                                        placeholder="Phone Number"
                                        {...register2("tel1", {
                                            required: {
                                                value: true,
                                                message: "Phone number is required"
                                            },
                                            pattern: {
                                                value: /^[\d\s()+-]+$/,
                                                message: "Invalid phone number format"
                                            }
                                        })} />
                                    <p style={{ color: "red" }}>{errors2.tel1?.message}</p>
                                </div>

                                <div className="mb-3">
                                    <Controller
                                        control={control2}
                                        name="country1"
                                        rules={{
                                            required: "Please Select Country.",
                                        }}
                                        defaultValue=""
                                        render={({ field: { onChange, onBlur, value, name, ref } }) => (
                                            <div className="mb-3">
                                                <Select
                                                    name={name}
                                                    ref={ref}
                                                    onChange={(e) => {
                                                        onChange(e);
                                                    }}
                                                    onBlur={onBlur}
                                                    value={value}
                                                    options={snap2.country1}
                                                    getOptionLabel={(e) => e.label}
                                                    getOptionValue={(e) => e.value}
                                                    placeholder="Select a Country*"
                                                    closeMenuOnSelect={true}
                                                    filterOption={createFilter({ matchFrom: "start" })}
                                                />
                                                <div style={{ color: "red" }}>
                                                    {errors2.country1 && errors2.country1.message}
                                                </div>
                                            </div>
                                        )}
                                    />
                                </div>

                                <div className="d-inline-block float-lg-end pt-3">
                                    <button type="submit" className="btn btn-primary btn-submit" id="submit2">{ requestCallback }<span><img src="/images/Checkout/arrow-icon.png" /></span></button>
                                </div>
                            </form>
                        }
                        
                    </div>
                </div>
            </div>
        </div>
        </div>
        </>
)
}
export default CallbackForm