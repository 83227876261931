import "./Checkout.scss"
import { useSnapshot } from "valtio";
import { DB, country } from "./store/store";
import { useNavigate, useParams } from "react-router-dom";
import ShareModal from "./componenets/ShareModal";
import { useState  } from "react";
import share_button from "../src/assets/images/model/share_button.svg";
import { useEffect } from "react";
import Download from "./componenets/Download";
import Header from "./Header";
import CallbackForm from './CallbackForm';
import CheckoutForm from './CheckoutForm';
import CheckoutBrandForm from './CheckoutBrandForm';
import FooterMenu from "./componenets/FooterMenu";

import { usePDF } from '@react-pdf/renderer'; // Document is needed for tender image to work/show in attachment PDF.
import AttachPDF from './ui/pdf/AttachPDF';

const Checkout = () => {
    const snap = useSnapshot(DB);
    const navigate = useNavigate();
    const [displayShare, setDisplayShare] = useState()
    const params = useParams();
    const { label, cat, model, brand } = params;
    if(brand !== undefined){
        DB.brand_slug = brand;
    }
    const [url, setUrl] = useState('');
    const [displayPopUp, setDisplayPopUp] = useState(false);

    let specs = '';
    let options = '';
    const newLabel = decodeURIComponent(label);
    const finalLabel = newLabel.replace(/_/g, ' ');
    specs += "Tender Name: " + DB.models[0].name + "\n";
    const body = document.body;

    body.classList.remove('dark-mode');

    snap.models[0].nodes.map((m, i) => {
        if (m.node_name !== "Rescue mode"){
            options += i === 0 ? m.node_name + ": " : "\n\n" + m.node_name + ": ";
            m.options.map((n, j) => (
                n.child.map((o, k) => {
                    if (m.id !== 8 && o.active === true) {
                        options += "\n" + n.name + ": " + o.name;
                    }
                })
            ))
        }
    });

    useEffect(() => {
        window.scrollTo(0, 0);
        const newDealer = snap.dealers
        .map(dealer => dealer.options.find(option => option.label === finalLabel))
        .filter(option => option !== undefined);

        const newLabels = new Set();
        const uniqueDealers = newDealer.filter(option => {
            if (newLabels.has(option.label)) {
                return false;
            } else {
                newLabels.add(option.label);
                return true;
            }
        });
        
        if (newDealer.length > 0) {
            DB.filterDealer = uniqueDealers;
        } else {
            DB.filterDealer = snap.dealers;
        }
    }, [])

    // console.log(DB.emailImg);
    // const img = snap.emailImg;
    const [instance, updateInstance] =  usePDF({ document: <AttachPDF objs={snap} img={snap.emailImg} /> });
    
    const openModel = () => {
        setDisplayPopUp('active')
        const body = document.body
        body.classList.add('active_code_modal')
    }
    const closeModel = () => {
        setDisplayPopUp()
        const body = document.body
        body.classList.remove('active_code_modal')
    }
    const closeShare = () => {
        setDisplayShare()
        const body = document.body
        body.classList.remove('active_code_modal')
    }
    const mailAFriend = () => {
        setDisplayShare()
        const body = document.body
        body.classList.remove('active_code_modal')
    }
    
    useEffect(() => {
        let currentUrl = '';
        if (label) {
            currentUrl = process.env.REACT_APP_WEB_URL + `/${label}/${cat}/${model}/?share=` + DB.code;
        } else {
            currentUrl = process.env.REACT_APP_WEB_URL + `/${cat}/${model}/?share=` + DB.code;
        }
        setUrl(currentUrl);
    }, [DB.code])

    useEffect(() => {

        if (DB.models[0].name === "" || DB.models[0].name === undefined){
            console.log("empty");
            alert("Something went wrong. You will be redirected to the main page shortly...")
            window.location.href = '/';
        }

        const handleBackButtonEvent = (e) => {
            e.preventDefault();
            const confirmLeave = window.confirm("Your changes will be lost if you leave this page. Do you want to leave?");
            if (confirmLeave) {
                navigate(-1);
            } else {
                window.history.pushState(null, null, window.location.pathname);
            }
        };

        window.addEventListener('popstate', handleBackButtonEvent);
        window.history.pushState(null, null, window.location.pathname);

        const handleBeforeUnload = (e) => {
            e.preventDefault();
            e.returnValue = "Your changes will be lost if you leave this page.";
        };
        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('popstate', handleBackButtonEvent);
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [navigate]);

    let optionTitleFlag = false;

    const getPodColorName = (modelName, optionName) => {
        if (modelName.includes("SportJet") && optionName === "Choose your tube pod colour") {
            return "Choose your cleat pod colour";
        } else if (modelName.includes("DieselJet") && optionName === "Choose your tube pod colour") {
            return "Choose your step pad colour";
        }
        return optionName;
    }

    const handleNavigation = () => {
        const shareParam = `?share=${DB.code}`;
    
        if (label) {
            navigate(`/${label}/${cat}/${model}/${shareParam}`);
        } else if (brand) {
            navigate(`/yacht-brand/${brand}/${cat}/${model}/${shareParam}`);
        } else {
            navigate(`/${cat}/${model}/${shareParam}`);
        }
    };

    return (
        <>
            <div className="top-wrapper">
                <Header />
                <div className="checkout-content-block">
                    <div className="max-wrap2">
                        <div className="row">
                            <div className="col-md-12 col-lg-6">
                                <h3 className="checkout-title">The final steps...</h3>
                            </div>
                            <div className="col-md-12 col-lg-6 checkout-para">
                                Just a few more steps and you'll receive your no obligation quote for your tender, designed to your specification outlined below.
                            </div>
                        </div>
                    </div>
                </div>

                <div className="two-column-accordion-layout">
                    <div className="max-wrap2">
                        <div className="row flex-column-reverse flex-lg-row">
                            <div className="col-md-12 col-lg-6 checkout-content-area">
                                <div className="content-sub-para pb-5">
                                    <h4>Your {DB.models[0].name}</h4>
                                    <p>Here are the details of your tender build</p>
                                </div>

                                <div className="accordion accordion-flush pb-3" id="accordionFlushExample">
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="flush-headingOne">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                                                Specification
                                            </button>
                                        </h2>
                                        <div id="flush-collapseOne" className="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                                            <div className="accordion-body specs">
                                                <div className="d-flex align-items-start justify-content-between border-bottom py-3"><span className="fw-bold">LOA</span> <span className="text-end">{DB.models[0].specifications[0].loa} </span></div>
                                                <div className="d-flex align-items-start justify-content-between border-bottom py-3"><span className="fw-bold">BEAM</span> <span className="text-end"> {DB.models[0].specifications[0].beam}</span></div>
                                                <div className="d-flex align-items-start justify-content-between border-bottom py-3"><span className="fw-bold">DRY WEIGHT</span> <span className="text-end">{DB.models[0].specifications[0].dry_weight}</span></div>
                                                <div className="d-flex align-items-start justify-content-between border-bottom py-3"><span className="fw-bold">OVERALL HEIGHT</span> <span className="text-end">{DB.models[0].specifications[0].overall_height} </span></div>
                                                <div className="d-flex align-items-start justify-content-between border-bottom py-3"><span className="fw-bold">ENGINE</span> <span className="text-end">{DB.models[0].specifications[0].engine} </span></div>
                                                <div className="d-flex align-items-start justify-content-between border-bottom py-3"><span className="fw-bold">FUEL CAPACITY</span> <span className="text-end">{DB.models[0].specifications[0].fuel_capacity} </span></div>
                                                <div className="d-flex align-items-start justify-content-between border-bottom py-3"><span className="fw-bold">MAX SPEED</span> <span className="text-end">{DB.models[0].specifications[0].max_speed}</span></div>
                                                <div className="d-flex align-items-start justify-content-between border-bottom py-3"><span className="fw-bold">TYPICAL FITMENT</span> <span className="text-end">{DB.models[0].specifications[0].typical_fitment} </span></div>
                                                <div className="d-flex align-items-start justify-content-between border-bottom py-3"><span className="fw-bold">SEATING</span> <span className="text-end">{DB.models[0].specifications[0].seating} </span></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="flush-headingTwo">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                                                Your Chosen Options
                                            </button>
                                        </h2>
                                        <div id="flush-collapseTwo" className="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                                            <div className="accordion-body">
                                                {
                                                    snap.models[0].nodes.map((m, i) => {
                                                        optionTitleFlag = true;
                                                        return (
                                                            m.options.map((n, j) => (
                                                                n.child.map((o, k) => {
                                                                    if (m.id !== 8 && o.active === true) {
                                                                        return (
                                                                            <div key={k} className={`parent mb-3 d-flex flex-column flex-lg-row pb-3 ${optionTitleFlag && 'border-top pt-4'}`}>
                                                                                <div className="child1 fw-bold">
                                                                                    <div className="pb-2 pb-lg-0">
                                                                                        {
                                                                                            optionTitleFlag ? m.node_name : ''
                                                                                        }
                                                                                        {optionTitleFlag = false}
                                                                                    </div>
                                                                                </div>

                                                                                <div className="child2 d-flex">
                                                                                    <div className="name2 pe-3">
                                                                                        {getPodColorName(DB.models[0].name, n.name)}
                                                                                    </div>
                                                                                    <div className="name3">
                                                                                        {o.name}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    }
                                                                })
                                                            ))
                                                        )
                                                    })
                                                }

                                                {snap.models[0]?.name?.split(" ")[0] === "SOLAS" &&
                                                    <div className="parent mb-3 d-flex flex-column flex-lg-row border-top pt-3">
                                                        <div className="child1 fw-bold pb-2 pb-lg-0">
                                                            <div>Rescue mode</div>
                                                        </div>

                                                        <div className="child2 d-flex">
                                                            <div className="name2 pe-3">
                                                                {snap.models[0].nodes[7].options[0].name}
                                                            </div>
                                                            <div className="name3">
                                                                {snap.models[0].nodes[7].options[0].child[0].active ? "ON" : "OFF"}
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="buttons d-flex flex-wrap">
                                    <a 
                                        className="btn btn_dark d-flex align-items-center" 
                                        onClick={() => 
                                            window.open(`mailto:?subject=Checkout this ${DB.models[0].name}&body=Check this link ${encodeURI(url)}`, '_blank')
                                        }
                                    >
                                        <span className="pe-2"><img src="/images/Checkout/email-icon.png" /></span>Email to a friend
                                    </a>
                                    <a onClick={openModel} className="btn btn_dark d-flex align-items-center">
                                        <span className="pe-2"><img src="/images/Checkout/download-icon.png" /></span> Download
                                    </a>

                                    <a  className="btn btn_white btn_acc d-flex align-items-center"
                                        onClick={() => {
                                            const body = document.body
                                            body.classList.add('active_code_modal')
                                            setDisplayShare('active')
                                        }}
                                    >
                                        <span><img src={share_button} alt="Share Buttton" /> Share Options</span>
                                    </a>
                                    <a className="btn btn_white btn_acc d-flex align-items-center" onClick={handleNavigation}>
                                        <span className="pe-2">
                                            <img src="/images/Checkout/reset-icon.png" alt="Reset Icon" />
                                        </span>
                                        Make Changes
                                    </a>
                                </div>
                            </div>
                            <div className="col-md-12 col-lg-6 accordion-img">
                                {/* <img className="w-100" src="/images/Checkout/checkout.png" alt="" /> */}
                                <img className="w-100" src={DB.pdfImg} alt="" />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="max-wrap">
                    <ShareModal onDrop={closeShare} displayShare={displayShare} url={url} code={DB.code} />
                    <Download onDrop={closeModel} displayPopUp={displayPopUp}/>
                </div>
                {(brand !== undefined) ? <CheckoutBrandForm /> : <CheckoutForm />}
                {(brand !== undefined) ? '' : <CallbackForm />}
            </div>

            <FooterMenu />
        </>
    )
}

export default Checkout