import plus from "../assets/images/model/plus.svg";
import minus from "../assets/images/model/minus.svg";
import reset from "../assets/images/model/reset.svg";
import resize from "../assets/images/model/resize.svg";
import reset_button from "../assets/images/model/reset_button.svg";
import download_button from "../assets/images/model/download_button.svg";
import share_button from "../assets/images/model/share_button.svg";
import drodpdown_button from "../assets/images/model/dropdown_icon.svg";
import './Model.scss';
import { useEffect, useState } from "react";
import ZoomImage from "./ZoomImage";
import { TransformComponent, useControls } from "react-zoom-pan-pinch";
import Download from "./Download";
import { useSnapshot } from "valtio";
import { DB } from "../store/store";
import ShareModal from "./ShareModal";
import { useParams } from "react-router";
import { displayShareHandler } from "../helper/helper";
import html2canvas from "html2canvas";

const Model = () => {

    const snap = useSnapshot(DB);
    const [darkMode, setDarkMode] = useState(false)
    const [displayDropdown, SetDisplayDropdown] = useState(false)
    const [displayPopUp, setDisplayPopUp] = useState(false)
    const [fullScreen, setFullScreen] = useState()
    const [displayShare, setDisplayShare] = useState()
    const [url, setUrl] = useState('');
    const params = useParams();
    const { label, cat, model } = params;

    const { zoomIn, zoomOut, resetTransform } = useControls()

    useEffect(() => {
        const body = document.body
        const toggle = document.querySelector('.toggle-inner')
        if (darkMode === true) {
            body.classList.add('dark-mode')
            toggle.classList.add('toggle-active')
        } else {
            body.classList.remove('dark-mode')
            toggle.classList.remove('toggle-active')
        }
    }, [darkMode])

    const dropdownHandler = () => {
        SetDisplayDropdown(prev => !prev)
    }

    const openModel = () => {
        setDisplayPopUp('active')
        const body = document.body
        body.classList.add('active_code_modal');

        const dwnImg = document.getElementById("model_img");

        html2canvas(dwnImg, {
            allowTaint: true,
            scale: 3.5,
            useCORS: true,
            backgroundColor: null,
        }).then(function (canvas) {
            DB.pdfImg = canvas.toDataURL("image/png");
        });

        html2canvas(dwnImg, {
            allowTaint: true,
            scale: 1,
            useCORS: true,
            backgroundColor: null,
        }).then(function (canvas) {
            DB.emailImg = canvas.toDataURL("image/png");
        });
    }
    const closeModel = () => {
        setDisplayPopUp()
        const body = document.body
        body.classList.remove('active_code_modal')
    }

    const handleFullScreen = () => {
        resetTransform()
        setFullScreen('enable_fullscreen')
        const body = document.body
        body.classList.add('enable_fullscreen')
        document.querySelector("#parent_block").classList.add('remove_fixed_position');
    }

    const handleToggle = () => {
        setFullScreen()
        const body = document.body
        body.classList.remove('enable_fullscreen');
        document.querySelector("#parent_block").classList.remove('remove_fixed_position');
        resetTransform()
    }

    useEffect(() => {
        let currentUrl = '';
        if (label) {
            currentUrl = process.env.REACT_APP_WEB_URL + `/${label}/${cat}/${model}/?share=` + DB.code;
        } else {
            currentUrl = process.env.REACT_APP_WEB_URL + `/${cat}/${model}/?share=` + DB.code;
        }
        setUrl(currentUrl);
        
        DB.new_url = url; //using this for PDF share link
    }, [DB.code])

    const closeShare = () => {
        setDisplayShare()
        const body = document.body
        body.classList.remove('active_code_modal')
    }

    return (
        <>
            <div className={`model`}>
                <div className="model-fixed">
                    <TransformComponent>
                        <ZoomImage />
                    </TransformComponent>

                    {
                        fullScreen &&
                        <div className="fullscreen_block">
                            <img src={resize} alt="Resize" onClick={handleToggle} />
                        </div>
                    }

                    <div className="desktop_view">
                        <div className="control_block">
                            <div className="control_title">
                                Image Controls
                            </div>


                            <div className="controls">
                                <div className="ind_controls">
                                    <div className="ind_controls_title">
                                        Zoom
                                    </div>
                                    <div>
                                        <img src={minus} alt="Minus" onClick={() => zoomOut()} />
                                    </div>
                                    <div>
                                        <img src={plus} alt="Plus" onClick={() => zoomIn()} />
                                    </div>
                                    <div>
                                        <img src={reset} alt="Reset" onClick={() => resetTransform()} />
                                    </div>
                                </div>
                                <div className="ind_controls">
                                    <div className="ind_controls_title">
                                        Dark Mode
                                    </div>

                                    <div id="toggle" onClick={() => darkMode === false ? setDarkMode(true) : setDarkMode(false)} >
                                        <div className={`toggle-inner ${darkMode ? "dark" : "light"}`}></div>
                                        <div className={`toggle-name ${darkMode ? "dark" : "light"}`}>{darkMode ? "On" : "Off"}</div>
                                    </div>

                                </div>
                                <div className="ind_controls">
                                    <div className="ind_controls_title">
                                        Full Screen
                                    </div>
                                    <div>
                                        <img src={resize} alt="Resize" onClick={handleFullScreen} />
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div className="buttons">
                            <button className="btn_dark reset" onClick={() => {
                                const reponse = window.confirm("Do you really want to reset?");

                                if (reponse) {
                                    window.location.reload();
                                }
                            }}>
                                <span><img src={reset_button} alt="Reset Buttton" /></span>
                                <span>Reset</span>
                            </button>
                            <button
                                className="download"
                                onClick={() => {
                                    openModel();
                                    displayShareHandler(params, snap);
                                }}
                            >
                                <span><img src={download_button} alt="Download Buttton" /></span>
                                <span>Download</span>
                            </button>
                            <button 
                                className="share" 
                                onClick={async() => {
                                    displayShareHandler(params, snap);
                                    setDisplayShare('active');

                                    const dwnImg = document.getElementById("model_img");

                                    await html2canvas(dwnImg, {
                                        scale: 3.5,
                                        allowTaint: true,
                                        useCORS: true,
                                        backgroundColor: null,
                                    }).then(function (canvas) {
                                        DB.pdfImg = canvas.toDataURL("image/png");
                                    });
                                    
                                    const dwnImg2 = document.getElementById("model_img");

                                    await html2canvas(dwnImg2, {
                                        scale: 1,
                                        allowTaint: true,
                                        useCORS: true,
                                        backgroundColor: null,
                                    }).then(function (canvas2) {
                                        DB.emailImg = canvas2.toDataURL("image/png");
                                    });
                                }}
                            >
                                <span><img src={share_button} alt="Share Buttton" /></span>
                                <span>Share</span>
                            </button>
                        </div>
                    </div>

                    <div className="mobile_view">
                        <div className="control_block">
                            <div className="control_title" onClick={dropdownHandler}>
                                <div>Image Controls</div>
                                <div><img src={drodpdown_button} alt="Drodpdown Button" /></div>
                            </div>


                            <div className={`controls ${displayDropdown ? 'dropdown-show' : 'dropdown-hide'}`}>
                                <div className="ind_controls">
                                    <div className="ind_controls_title">
                                        Zoom
                                    </div>
                                    <div>
                                        <img src={minus} alt="Minus" onClick={() => zoomOut()} />
                                    </div>
                                    <div>
                                        <img src={plus} alt="Plus" onClick={() => zoomIn()} />
                                    </div>
                                    <div>
                                        <img src={reset} alt="Reset" onClick={() => resetTransform()} />
                                    </div>
                                </div>
                                <div className="ind_controls">
                                    <div className="ind_controls_title">
                                        Dark Mode
                                    </div>

                                    <div id="toggle" onClick={() => darkMode === false ? setDarkMode(true) : setDarkMode(false)} >
                                        <div className={`toggle-inner ${darkMode ? "toggle-active" : ""}`}></div>
                                        <div className={`toggle-name ${darkMode ? "dark" : "light"}`}>{darkMode ? "ON" : "Off"}</div>
                                    </div>

                                </div>
                                <div className="ind_controls">
                                    <div className="ind_controls_title">
                                        Full Screen
                                    </div>
                                    <div>
                                        <img src={resize} alt="Resize" onClick={handleFullScreen} />
                                    </div>
                                </div>
                            </div>

                            <div className={`buttons ${displayDropdown ? 'dropdown-show' : 'dropdown-hide'}`}>
                                <button className="btn_dark" onClick={() => {
                                    const reponse = window.confirm("Do you really want to reset?");

                                    if (reponse) {
                                        window.location.reload();
                                    }
                                }}>
                                    <span><img src={reset_button} alt="Reset Buttton" /></span>
                                    <span>Reset</span>
                                </button>
                                <button className="download" onClick={openModel}>
                                    <span><img src={download_button} alt="Download Buttton" /></span>
                                    <span>Download</span>
                                </button>
                                <button 
                                    className="share" 
                                    onClick={() => {
                                        displayShareHandler(params, snap);
                                        setDisplayShare('active')
                                    }}
                                >
                                    <span><img src={share_button} alt="Share Buttton" /></span>
                                    <span>Share</span>
                                </button>
                                {displayPopUp &&
                                    <Download onDrop={closeModel} downloadImg={true} />
                                }
                            </div>
                        </div>
                    </div>

                    <Download onDrop={closeModel} displayPopUp={displayPopUp} url={url}/>
                    <ShareModal onDrop={closeShare} displayShare={displayShare} url={url} code={DB.code} />
                </div>
            </div>
        </>
    )
}

export default Model