import { useEffect, useState } from 'react';
import { useForm } from "react-hook-form"
import close_icon from "../assets/images/close_icon.svg";
import './GenerateCode.scss';
import copy from "copy-to-clipboard";
import copy_icon from "../assets/images/copy_icon.svg";
import facebook from '../assets/social/facebook-f.svg'
import twitter from '../assets/social/x-twitter.svg'
import whatsapp from '../assets/social/whatsapp.svg'
import mail from '../assets/social/envelope-regular.svg'
import linkedin from '../assets/social/linkedin-in.svg'
import { DB } from '../store/store';
import { useSnapshot } from "valtio";
import { postData, postFormData } from "../api/api";
import { usePDF } from '@react-pdf/renderer'; // Document is needed for tender image to work/show in attachment PDF.
import AttachPDF from '../ui/pdf/AttachPDF';
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import html2canvas from 'html2canvas';

const ShareModal = ({ onDrop, displayShare, url, code }) => {
    const [copied, setCopied] = useState();
    const [userPop, setUserPopup] = useState(0);
    const [userEmailPop, setUserEmailPopup] = useState(0);
    const [requestSubmit, setRequestSubmit] = useState('Submit');
    const [requestEmailSubmit, setRequestEmailSubmit] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [sendToWilliams, setSendToWilliams] = useState(true);
    const formId = process.env.REACT_APP_FORM_ID;

    const snap = useSnapshot(DB);

    const copyURLToClipboard = () => {
        copy(url);
        setCopied(2)

        setTimeout(() => {
            setCopied(0)
        }, 3000);
    }

    const showUserPopup = () => {
        setUserPopup(0);
        setUserPopup(2);
        setUserEmailPopup(1);
    }

    const onSubmitUserEmail = async (data) => {
        setRequestSubmit('Sending');
        let id = 0;
        let newImg;
        await fetch(DB.emailImg)
        .then(res => res.blob())
        .then(r => {
            //console.log(r)
            newImg = r;
        })
        
        var formdata = new FormData();
        formdata.append("input_1", data.user_email);
        formdata.append("input_2", DB.active_dealer_email);
        formdata.append("input_8", DB.active_brand_email);
        formdata.append("input_9", sendToWilliams ? "sales@williamsjettenders.com" : "");
        formdata.append("input_4", instance.blob, "WJT.pdf");
        formdata.append("input_7", newImg, "Tender.png");

        await postFormData(`${process.env.REACT_APP_API_URL}gf/v2/forms/${formId}/submissions`, formdata).then((data) => {
            if (data.is_valid){
                console.log('success');
                // setTimeout(() => {
                //     setSuccessMsg(false);
                // }, 6000);
            }else{
                alert ("Server error, please try after sometime.");
            }
        });

        reset2();
        setRequestSubmit('Submit');
        setIsSubmitted(true);
    };

    const {
        register: register2,
        formState: { errors: errors2 },
        handleSubmit: handleSubmit2,
        reset: reset2
    } = useForm();

    const handleCancel = () => {
        setUserPopup(0);
        setUserEmailPopup(0);
    }

    const [instance, updateInstance] =  usePDF({ document: <AttachPDF objs={snap} img={snap.emailImg} /> });

    useEffect(() => {
        updateInstance(<AttachPDF objs={snap} img={snap.emailImg} />);
    }, [snap]);

    useEffect(() => {
        if (isSubmitted) {
            const timer = setTimeout(() => {
                setIsSubmitted(false);
                setUserPopup(0);
                setUserEmailPopup(0);
            }, 3000);
    
            return () => clearTimeout(timer);
        }
    }, [isSubmitted]);

    return (
        <div className={`code_modal ${displayShare} share-drop`}>
            <div className='close_icon' onClick={onDrop}>
                <img src={close_icon} alt="Close Icon" width="16px"/>
            </div>
            <div className='social-icon-block w-100'>
                <div>
                    <a href={`http://www.linkedin.com/shareArticle?mini=true&url=${url}`} target='_blank'>
                        <img src={linkedin} alt='linkedin' />
                    </a>
                    <a href={`https://api.whatsapp.com/send?text=${url}`} target='_blank'>
                        <img src={whatsapp} alt='whatsapp' />
                    </a>
                    <a href={`http://www.twitter.com/intent/tweet?url=${url}`} target='_blank'>
                        <img src={twitter} alt='twitter' />
                    </a>
                    <a href={`https://www.facebook.com/sharer/sharer.php?u=${url}`} target='_blank'>
                        <img src={facebook} alt='facebook' />
                    </a>
                </div>

                <div>
                    <div>
                        Your unique configuration link:
                        <div className='fw-bold pt-2'>
                            {url}
                            <span>
                                {code && !copied && (
                                    <img 
                                        style={{
                                            cursor: 'pointer', 
                                            width: "45px", 
                                            height: "20px", 
                                            display: "inline", 
                                            margin: "0", 
                                            transform: "translate(10px, -2px)"
                                        }}
                                        title='Copy' 
                                        src={copy_icon} 
                                        alt='copyicon' 
                                        onClick={copyURLToClipboard} 
                                    />
                                )}
                            </span>
                        </div>
                    </div>
                    <p className='clipboard_copy_share fw-bold'>{copied === 2 ? 'Copied' : ' '}</p>
                </div>

                {code ? 
                    <div className='w-100'>
                    Share your configuration by email:

                    {requestEmailSubmit && <p className='pt-2'>Sending ...</p>}

                    {(userPop === 0 && userEmailPop === 0) &&
                        <div className='share_mail_buttons pt-3'>
                            <button className="btn btn_dark btn-width" onClick={showUserPopup}><img src={mail} alt='mail' /> <span>Send to Myself</span>
                            </button>
                        </div>
                    }

                    {isSubmitted ? (
                        <div className="thank-you-message pt-2">
                            <h3>Thank you!</h3>
                            <p>Your email has been sent successfully.</p>
                        </div>
                    ) : (
                    (userPop === 2) && 
                        <div className='share_mail_buttons pt-4'>
                            <form key={1} onSubmit={handleSubmit2(onSubmitUserEmail)} id="submitcallback" style={{width: '60%'}}>
                                <div className="input-class">
                                    <input
                                        id="user_email"
                                        type="text"
                                        className="form-control text-center"
                                        placeholder="User email*"
                                        {...register2("user_email", {
                                            required: {
                                                value: true,
                                                message: "User Email is required"
                                            },
                                            pattern: {
                                                value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                                                message: "Invalid email format"
                                            }
                                        })} 
                                    />
                                    <p style={{ color: "red", textAlign: "center", padding: "5px 0px 0px 5px" }}>{errors2.user_email?.message}</p>
                                    <div className="pb-3">
                                        <input
                                            type="checkbox"
                                            id="dealer_email"
                                            checked={sendToWilliams}
                                            onChange={(e) => setSendToWilliams(e.target.checked)}
                                            style={{cursor: 'pointer'}}
                                        />
                                        <label htmlFor="dealer_email" className="ps-2" style={{cursor: 'pointer'}}>Send to Williams</label>
                                    </div>
                                </div>
                                <div className="d-inline-block pt-1 d-flex justify-content-center bottom-buttons">
                                    <button type="submit" className="btn btn-primary btn-submit m-0" id="submit2">{ requestSubmit }<span><img className='submit-img' src="/images/Checkout/arrow-icon.png" /></span></button>
                                    <button  className="btn btn-primary btn-cancel m-0" onClick={handleCancel}>Cancel<span><FontAwesomeIcon icon={faTimes} className='ps-2' />
                                    </span></button>
                                </div>
                            </form>
                        </div>
                    )}
                    </div>
                    :
                    <div className="d-flex justify-content-center align-items-center flex-column">
                        <div className="spinner-border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                        <div className="pt-4">&nbsp;&nbsp; Loading...</div>
                    </div>
                }
            </div>
        </div>
    )
}

export default ShareModal