import React from 'react'
// import { useSnapshot } from 'valtio';
import { DB } from '../../store/store';

const AddImage = ({ Protectors, node, option }) => {

    // const snap = useSnapshot(DB);

    const handleClick = (index, cat) => {

        // DB.display_weather_pro_col = !DB.display_weather_pro_col;

        const index_id = DB.models[0].nodes[node].options[option].child[index].id;

        const cat_list = DB.models[0].nodes[5].options[option].child.filter((f) => f.category === cat);

        cat_list.map((m)=> {
            if(m.id !== index_id){
                m.active = false;
            }

            if (m.name === "Bimini"){
                DB.models[0].nodes[5].options[1].active = !DB.models[0].nodes[5].options[1].active;
                if (DB.models[0].nodes[5].options[1].child.length) {
                    DB.models[0].nodes[5].options[1].child[0].active = true;
                } else {
                    DB.models[0].nodes[5].options[1].active = false;
                }
                if (!DB.models[0].nodes[5].options[1]?.active) {
                    DB.models[0].nodes[5].options[1]?.child.map((c) => {
                        c.active = false;
                    })
                }
            }else{
                DB.models[0].nodes[5].options[2].active = !DB.models[0].nodes[5].options[2].active;
                if (DB.models[0].nodes[5].options[2].child.length) {
                    DB.models[0].nodes[5].options[2].child[0].active = true;
                } else {
                    DB.models[0].nodes[5].options[2].active = false;
                }
                if (!DB.models[0].nodes[5].options[2]?.active) {
                    DB.models[0].nodes[5].options[2]?.child.map((c) => {
                        c.active = false;
                    })
                }
            }
        })

        DB.models[0].nodes[node].options[option].child[index].active = !DB.models[0].nodes[node].options[option].child[index].active;
    }

    return (
        <div className='image_main_block mb-5'>
            {Protectors[0].child.length ?
                Protectors[0].child.map((m, i)=> (
                    <div key={m.id} className='single_block'>
                        <div className={`single_image ${m.active ? 'block_active' : ''}`}><img onClick={() => handleClick(i, m.category)} src={m.value} alt={m.name} /></div>
                        <p className='image_text'>{m.name}</p>
                        <button onClick={() => handleClick(i, m.category)} className={`image_button ${m.active ? 'active' : ''}`}>{!m.active ? <span>+ Add</span> : <span>Remove</span>}</button>
                    </div>
                ))
                :
                <div>No options available</div>
        }

        </div>
    )
}

export default AddImage