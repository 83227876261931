import Header from "./Header"
import Model from "./componenets/Model"
import Panel from "./componenets/Panel"
import "./Visualiser.scss"
import { useNavigate, useParams } from 'react-router-dom';
import { TransformWrapper } from "react-zoom-pan-pinch";
import { useSearchParams } from "react-router-dom/dist";
import { DB } from "./store/store";
import { getData, getData2 } from "./api/api";
import { updateModel } from "./helper/helper";
import { useSnapshot } from "valtio";
import { useEffect } from "react";

const Visualiser = () => {
    const snap = useSnapshot(DB);
    const params = useParams();
    const { label, cat, model } = params;
    const navigate = useNavigate();
    const { brand } = params;
    if(brand !== undefined){
        DB.brand_slug = brand;
    }
    //console.log(DB.active_brand_email);

    // below code is for getting active email
    const newLabel = decodeURIComponent(label);
    const finalLabel = newLabel.replace(/_/g, ' ');
        
    const newDealer = snap.dealers
    .map(dealer => dealer.options.find(option => option.label === finalLabel))
    .filter(option => option !== undefined);

    useEffect(()=>{
        if(newDealer.length > 0){
            DB.active_dealer_email = newDealer[0].email;
        }else{
            DB.active_dealer_email = "";
        }
    }, [newDealer])
    // end of code for getting active email

    const updateData = async (cat, model) => {
        await getData(process.env.REACT_APP_API_URL2 +"wp/v2/tenders/?slug="+cat+"-"+model+"&acf_format=standard").then((data) => {
            updateModel(data[0]);
            DB.API_ready_for_tender = true
        });
    }

    const [queryParameters] = useSearchParams();
    const share = queryParameters.get("share") ? queryParameters.get("share") : "";

    const checkShare = async () => {
        await updateData(cat, model);
        if(share){
            try {
                await getData2(process.env.REACT_APP_API_URL +'gf/v2/forms/3/entries?search={"field_filters":[{"key":"3","value":"'+share+'"}]}').then((data) => {
                    if (data.entries) {
                        data.entries.map((x, y) => {
                            const active_decode = Object.values(x);

                            if(active_decode[1] === share){
                                if (active_decode[2] != cat || active_decode[3] != model) {
                                    if (label) {
                                        window.location.href =`/${label}/${active_decode[2]}/${active_decode[3]}/?share=${share}`;
                                    } else {
                                        window.location.href =`/${active_decode[2]}/${active_decode[3]}/?share=${share}`;
                                    }
                                } else {
                                    DB.models[0].nodes?.map((m, n) => {
                                        m.options.map((a, b) => {
                                            a.child.map((c, d) => {
                                                c.active = JSON.parse(active_decode[0]).includes(c.active_id);
                                            });
                                        });
                                    })
                                }
                            }
                            
                        })
                    } else {
                        console.log("No such document!");
                    }
                });


            } catch (e) {
                console.error("Error reading document: ", e);
            }
        }
    }
    
    useEffect(()=>{
        checkShare();

        const handleBeforeUnload = (e) => {
            e.preventDefault();
            e.returnValue = "Your changes will be lost if you leave this page.1";
        };
        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [])

    return (
        <>
            <Header />
            <div className="primary_block max-wrap2 justify-content-between w-100" id="parent_block">

                {
                    (DB.API_ready_for_tender && DB.API_ready) ?
                    <div className="row">
                        <div className="col-lg-8 main_image_block">
                            <TransformWrapper>
                                <Model />
                            </TransformWrapper>
                        </div>
                        <div className="col-lg-4">
                            <Panel />
                        </div>
                    </div>
                    :
                    <div className="d-flex justify-content-center align-items-center flex-column w-100" style={{height: "100vh"}}>
                        <div className="spinner-border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                        <div className="pt-4">&nbsp;&nbsp; Loading...</div>
                    </div>
                }
            </div>
        </>
    )
}

export default Visualiser