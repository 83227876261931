import React from 'react'
import AddImage from './sub_components/AddImage'
import ColorPicker from './sub_components/ColorPicker';
import { useSnapshot } from 'valtio';
import { DB } from '../store/store';

const WeatherProtection = ({ item }) => {
    const snap = useSnapshot(DB);
    const mainData = item.nodes.filter((f) => f.node_name === "Weather Protection");
    const Protectors = mainData[0].options.filter((f) => f.name === "Protectors");
    const Protectors_1 = mainData[0].options.filter((f) => f.name === "Weather Protection Colour (Bimini)");
    const Protectors_2 = mainData[0].options.filter((f) => f.name === "Weather Protection Colour (Spray Dodger)");

    return (
        <> 
            {mainData[0].active &&
                <div className='wea_protectors'>
                    <div>
                        <h4>{mainData[0].node_name}</h4>
                    </div>
                    {Protectors[0].active && <AddImage
                        Protectors = {Protectors}
                        node = {5}
                        option = {0} 
                    />}

                    {Protectors_1[0].active && <ColorPicker
                        colorData = {Protectors_1}
                        className="pt-3 mb-0"
                        node = {5}
                        option = {1} 
                    />}

                    {Protectors_2[0].active && <ColorPicker
                        colorData = {Protectors_2}
                        className="pt-3 mb-0 sd_protector"
                        node = {5}
                        option = {2} 
                    />}
                </div>
            }
        </>
    )
}

export default WeatherProtection