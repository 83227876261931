import React from 'react';
import { Page, Text, View, Document, StyleSheet, Image, Font, Link } from '@react-pdf/renderer';
import logo from "../../assets/images/wjt_logo.png";
import { DB } from '../../store/store';
import { useParams } from 'react-router-dom';

// Register the "Roboto" font from Google Fonts

Font.register({
    family: 'Poppins',
    fonts: [
        {
        src: 'https://fonts.gstatic.com/s/poppins/v22/pxiByp8kv8JHgFVrLDz8V1tvFP-KUEg.ttf',
        },
        {
        src: 'https://fonts.gstatic.com/s/poppins/v22/pxiByp8kv8JHgFVrLBT5V1tvFP-KUEg.ttf',
        fontWeight: 'bold',
        },
        {
        src: 'https://fonts.gstatic.com/s/poppins/v22/pxiDyp8kv8JHgFVrJJLm21llEN2PQEhcqw.ttf',
        fontWeight: 'normal',
        fontStyle: 'italic',
        },
        {
        src: 'https://fonts.gstatic.com/s/poppins/v22/pxiByp8kv8JHgFVrLDz8V1tvFP-KUEg.ttf',
        fontWeight: 300,
        },
        {
        src: 'https://fonts.gstatic.com/s/poppins/v22/pxiDyp8kv8JHgFVrJJLmv1plEN2PQEhcqw.ttf',
        fontWeight: 200,
        fontStyle: 'italic',
        },
        {
        src: 'https://fonts.gstatic.com/s/poppins/v22/pxiByp8kv8JHgFVrLGT9V1tvFP-KUEg.ttf',
        fontWeight: 500,
        },
        {
        src: 'https://fonts.gstatic.com/s/poppins/v22/pxiByp8kv8JHgFVrLEj6V1tvFP-KUEg.ttf',
        fontWeight: 600,
        },
    ],
});

// Create styles
const styles = StyleSheet.create({
    details_wrapper_main: {
        paddingLeft: 25,
        paddingRight: 25,
        fontWeight: 400,
        fontStyle: 'normal',
        width: '100%',
        position: 'absolute',
        top: 0,
    },
    details_wrapper: {
        paddingLeft: 60,
        paddingRight: 60,
        fontWeight: 400,
        fontStyle: 'normal',
        width: '100%',
    },
    page: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        position: 'relative',
        width: '100%',
    },
    page2: {
        backgroundColor: '#e9e9eb',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderBottom: '2px solid #000',
    },
    page3: {
        backgroundColor: '#e9e9eb',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderTop: '2px solid #000',
        position: 'absolute',
        bottom: 0,
        width: '100%',
        padding: '10px 25px',
    },
    section: {
        width: '50%',
        textAlign: 'left',
    },
    section2: {
        paddingRight: 20,
    },
    section: {
        width: '50%',
        textAlign: 'left',
    },
    logo: {
        width: '70%',
    },
    section3: {
        width: '50%',
        textAlign: 'left',
    },
    section4: {
        width: '50%',
        textAlign: 'right',
    },
    logo3: {
        width: '20%',
        alignSelf: 'flex-end',
    },
    details_title: {
        marginBottom: 10,
        borderBottom: '2px solid #000',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-end',
        justifyContent: 'space-between',
    },
    details_title2: {
        marginBottom: 10,
        marginTop: 10,
    },
    details_titleH1: {
        margin: '0 auto 10px 0',
        color: '#ffffff',
        fontWeight: 600,
        fontStyle: 'normal',
        fontSize: '25px',
        textAlign: 'left',
        fontFamily: 'Poppins',
        textTransform: 'uppercase',
        letterSpacing: '2px',
    },
    details_wrapper_titleH1: {
        margin: '0 auto 10px 0',
        color: '#000000',
        fontWeight: 600,
        fontStyle: 'normal',
        fontSize: '20px',
        textAlign: 'left',
        fontFamily: 'Poppins'
    },
    details_titleH3: {
        margin: '0 0 10px',
        color: '#000000',
        fontWeight: 700,
        fontStyle: 'normal',
        fontSize: '16px',
    },
    details_outer_wrapper: {
        padding: '10px 0 5px',
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
    },
    details_outer_wrapper1: {
        borderTop: '2px solid rgb(225, 225, 225)',
        padding: '10px 0 0px',
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
    },
    view_category_name: {
        width: '100%',
    },
    details_first_column: {
        paddingRight: '10px',
        fontSize: '10px',
        color: '#000000',
        fontWeight: 600,
        fontStyle: 'normal',
        width: '200px',
    },
    details_second_column: {
        marginRight: '5px',
        display: 'flex',
        flexDirection: 'row',
        width: '350px',
    },
    details_third_column: {
        marginBottom: '0.5px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        width: '210px',
    },
    details_first_column_text: {
        fontSize: '9px',
        fontWeight: 300,
        fontFamily: 'Poppins',
    },
    details_third_column_text: {
        color: '#0b0b0b',
        fontWeight: '400',
        width: '90%',
        fontSize: '9px',
        fontWeight: 300,
        fontFamily: 'Poppins',
    },
    section_img: {
        margin: '60px auto 0',
        width: '100%',
        position: 'relative',
    },
    pageWrapper: {
        paddingTop: 45,
        position: 'relative',
        backgroundColor: '#000000',
    },
    firstPageWrapper: {
        paddingBottom: 45,
    },
    brand_logo: {
        width: 120,
        height: 80,
        marginBottom: 10
    },
    tender_build: {
        fontSize: '12px',
        color: '#ffffff',
        paddingBottom: 5,
        fontFamily: 'Poppins'
    },
    tender_summary: {
        fontSize: '8px',
        color: '#ffffff',
        fontFamily: 'Poppins'
    },
    tender_build2: {
        fontSize: '12px',
        paddingBottom: 5,
        fontFamily: 'Poppins'
    },
    tender_summary2: {
        fontSize: '8px',
        fontFamily: 'Poppins',
    },
    section_jetmodel: {
        margin: '80px auto 0',
        position: 'relative',
    },
    backgroundImage: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '120vw',
        height: '120vh',
        objectFit: 'cover',
    },
    link_section: {
        paddingTop: 20,
        fontSize: '10px',
        fontFamily: 'Poppins',
        marginBottom: '60px',
    },
    link: {
        fontWeight: 600,
        fontSize: '8px',
        fontFamily: 'Poppins',
    },
    link_text: {
        paddingTop: 20,
        fontSize: '8px',
        fontFamily: 'Poppins',
        fontWeight: 200,
        fontStyle: 'italic',
    },
    footer_link: {
        textDecoration: 'none',
    },
    footer_text: {
        color: '#000000',
        fontSize: '8px',
        fontFamily: 'Poppins',
    },
    footer_top_section: {
        paddingBottom: 5,
    },
    footer_bottom_section: {
        paddingTop: 5,
    },
    unique: {
        fontSize: '8px',
        fontFamily: 'Poppins',
        fontWeight: 300,
    },
    remove_border: {
        border: 'none',
    }
});

// Create Document Component
const ViewDetails = ({ objs }) => {
    const params = useParams();
    const { brand } = params;
    let optionTitleFlag = false;

    if(brand !== undefined){
        DB.brand_slug = brand;
    }

    const getPodColorName = (modelName, optionName) => {
        if (modelName.includes("SportJet") && optionName === "Choose your tube pod colour") {
            return "Choose your cleat pod colour";
        } else if (modelName.includes("DieselJet") && optionName === "Choose your tube pod colour") {
            return "Choose your step pad colour";
        }
        return optionName;
    }

    return (
        <Document>
            <Page wrap size="A4" style={[styles.pageWrapper, styles.firstPageWrapper]}>
                
                <View style={styles.details_wrapper_main}>

                    {/* <Image
                        src={DB.backgroundImagePath}
                        style={styles.backgroundImage}
                    /> */}

                    <View style={styles.page}>
                        <View style={styles.section}>
                            <Image style={styles.logo} src={{ uri: DB.wjtLogoWhite, method: "GET", headers: { "Cache-Control": "no-cache" }, body: "" }} />
                        </View>

                        <View>
                            <Text style={styles.tender_build}>Build My Tender</Text>
                            <Text style={styles.tender_summary}>Specification Summary</Text>
                        </View>
                    </View>

                    <View style={styles.section_img}>
                        {/* <Image src={img} /> */}
                        <Image src={{ uri: DB.pdfImg, method: "GET", headers: { "Cache-Control": "no-cache" }, body: "" }} />
                    </View>

                    <View style={styles.section_jetmodel}>
                        <Text style={styles.details_titleH1}>
                            {objs.models[0].name}
                        </Text>
                    </View>
                </View>
            </Page>

            <Page wrap size="A4">
                <View style={styles.page2}>
                    <View style={styles.section}>
                        <Image style={styles.logo} src={{ uri: logo, method: "GET", headers: { "Cache-Control": "no-cache" }, body: "" }} />
                    </View>

                    <View style={styles.section2}>
                        <Text style={styles.tender_build2}>Build My Tender</Text>
                        <Text style={styles.tender_summary2}>Specification Summary</Text>
                    </View>
                </View>

                <View style={styles.details_wrapper}>
                    <View style={styles.details_title}>
                        <Text style={styles.details_wrapper_titleH1}>
                            {objs.models[0].name}
                        </Text>

                        <View style={styles.brand_logo}>
                            {/* This code and fallback is added to avoid text '' warning issue */}
                            {DB.brand_logo_path && DB.brand_logo_path.match(/\.(jpeg|jpg|png|gif|svg)$/i) ? (
                                <Image src={DB.brand_logo_path} />
                            ) : (
                                <Text></Text>
                            )}
                        </View>
                    </View>

                    <View wrap>
                        {
                            objs.models[0].nodes.map((m, i) => {
                                optionTitleFlag = true;
                                return(
                                    m.options.map((n, j) => (
                                        n.child.map((o, k) => {
                                            if(m.id !== 8 && o.active === true){
                                                return(
                                                    <View key={`${i}-${j}-${k}`} style={[optionTitleFlag ? styles.details_outer_wrapper1 : styles.details_outer_wrapper, m.node_name === "Engine" && styles.remove_border]}>
                                                        <View style={styles.details_first_column}>
                                                            <Text style={styles.details_first_column_text}>
                                                                {optionTitleFlag ? m.node_name : ''}
                                                                {optionTitleFlag = false}
                                                            </Text>
                                                        </View>
                                                        
                                                        <View style={styles.details_second_column}>
                                                            <View style={styles.view_category_name}>
                                                                <Text style={styles.details_third_column_text}>
                                                                    {getPodColorName(DB.models[0].name, n.name)}
                                                                </Text>
                                                            </View>
                                                            <View style={styles.details_second_column} >
                                                                <View style={styles.details_third_column}> 
                                                                    <Text style={styles.details_third_column_text}>
                                                                        {o.name}
                                                                    </Text>
                                                                </View>
                                                            </View>
                                                        </View>
                                                    </View>
                                                )
                                            }
                                        })
                                    ))
                                )
                            })
                        }
                    </View>

                    {objs.models[0]?.name?.split(" ")[0] === "SOLAS" &&
                        <View style={styles.details_outer_wrapper1}>
                            <View style={styles.details_first_column}>
                                <Text style={styles.details_first_column_text}>
                                    Rescue mode
                                </Text>
                            </View>
                            
                            <View style={styles.details_second_column}>
                                <View style={styles.view_category_name}>
                                    <Text style={styles.details_third_column_text}>
                                        {objs.models[0].nodes[7].options[0].name}
                                    </Text>
                                </View>
                                <View style={styles.details_second_column} >
                                    <View style={styles.details_third_column}> 
                                        <Text style={styles.details_third_column_text}>
                                        {objs.models[0].nodes[7].options[0].child[0].active ? "ON" : "OFF"}
                                        </Text>
                                    </View>
                                </View>
                            </View>
                        </View>
                    }

                    <View style={styles.link_section}>
                        <Text style={styles.unique}>Your unique configurator link:</Text>
                        <Text style={styles.link}>{objs.new_url+objs.code}</Text>
                        <Text style={styles.link_text}>Build my tender image is created as a visual representation, and not to be treated as an exact match. Please also note that some accessories may not be visible on your tender. The product specification shown has been generated using our Build My Tender configurator engine and may differ slightly from the quotation you receive.</Text>
                    </View>
                </View>

                <View style={styles.page3}>
                    <View style={styles.section3}>
                        <Text style={[styles.footer_text, styles.footer_top_section]}>If you have any questions, please contact our sales team</Text>
                        <Text style={styles.footer_text}>E: sales@williamsjettenders.com</Text>
                        <Link style={styles.footer_link} src="tel:+441865341134">
                            <Text style={styles.footer_text}>+44 (0)1865 341134</Text>
                        </Link>
                        <Text style={[styles.footer_text, styles.footer_bottom_section]}>williamsjettenders.com</Text>
                    </View>

                    <View style={styles.section4}>
                        <Image style={styles.logo3} src={DB.wLogoPath} />
                    </View>
                </View>
            </Page>
        </Document>
    )
}

export default ViewDetails