import './App.scss';

import { useLocation, useRoutes } from 'react-router-dom'
import Route from './route';
import { useEffect } from 'react';
import { DB } from './store/store';
import { getData } from './api/api';
import { useSnapshot } from 'valtio';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function App() {
    let query = useQuery();
    const snap = useSnapshot(DB);
    
    let dealer1 = [];
    let dealer2 = [];
    let dealer3 = [];
    let regions = [];
    //let yachts = [];
    let brands = [];
    let brandId = 0;
    const updateData = async () => {
        await getData(process.env.REACT_APP_API_URL +"custom/menu/2").then((data) => {
            DB.headerMenu = data; // JSON data parsed by `data.json()` call
         });

        await getData(process.env.REACT_APP_API_URL +"custom/menu/3").then((data) => {
            DB.footerMenu = data; // JSON data parsed by `data.json()` call
        });

        await getData(process.env.REACT_APP_API_URL +"wp/v2/region?per_page=100").then((data) => {
            regions = [];
            data.map((m, i) => {
                regions.push({ name: m.name, id: m.id })
            })
        });
        regions.map((m, i) => {
            regions[i].name = m.name.replace("&#8217;", "'");
            regions[i].name = m.name.replace("&#8211;", "–");
            regions[i].name = m.name.replace("&#038;", "&");
            regions[i].name = m.name.replace("&AMP;", "&");
            regions[i].name = m.name.replace("&amp;", "&");
        })
        DB.regions = [];
        await DB.regions.push(...regions);

        //console.log(DB.brand_slug);
        if(DB.brand_slug !== ''){
            await getData(process.env.REACT_APP_API_URL +"wp/v2/brand/?slug="+DB.brand_slug+"&acf_format=standard").then((data) => {
                brands = [];
                data.map((m, i) => {
                    brandId = m.id;
                    brands.push({ name: m.name, id: m.id, slug: m.slug, email: m.acf.brand_email, logo: m.acf.brand_logo, contact_name: m.acf.brand_contact_name});
                    const temp = brands.filter((f) => (f.slug === DB.brand_slug));
                    DB.brand_logo_path = (temp[0].logo.sizes !== undefined) ? temp[0].logo.sizes.large : '';
                    DB.brand_name = temp[0].name;
                    DB.active_brand_email = temp[0].email;
                    DB.brand_contact_name = temp[0].contact_name;
                })
            });
            
            if(brandId > 0){
                await getData(process.env.REACT_APP_API_URL +"wp/v2/yacht/?per_page=100&brand="+brandId).then((data) => {
                    DB.yachts = [];
                    data.map((m, i) => {
                        const temp = brands.filter((f) => (f.id === m.brand[0]));
                        const brand_name = temp[0].name;
                        DB.yachts.push({ label: brand_name+" "+m.title.rendered, value: m.id, tenders_available: m.acf.tenders_available})
                    })
                });
            }
            await getData(process.env.REACT_APP_API_URL +"wp/v2/tenders/?per_page=100&acf_format=standard").then((data) => {
                DB.all_tenders = [];
                data.map((m, i) => {
                    DB.all_tenders.push({ label: m.title.rendered, value: m.id, td_specification: m.acf.td_specification, yatcht_images: m.acf.td_specification.yacht_image.sizes})
                })
            });

        }

        await getData(process.env.REACT_APP_API_URL +"wp/v2/dealer/?per_page=100&page=1").then((data) => {
            dealer1 = [];
            data.map((m, i) => {
                if (m.acf.dealer_type === "Sales Centre" || m.acf.dealer_type === "Service & Sales Centre"){
                    m.region.map((n, j) => {
                        dealer1.push({ label: m.title.rendered, value: m.id, region: n, id: m.id, email: m.acf.email, additional_logic: m.acf.additional_logic_for_finishing_touches })
                    })
                }
            })
        });

        await getData(process.env.REACT_APP_API_URL +"wp/v2/dealer/?per_page=100&page=2").then((data) => {
            dealer2 = [];
            data.map((m, i) => {
                if (m.acf.dealer_type === "Sales Centre" || m.acf.dealer_type === "Service & Sales Centre"){
                    m.region.map((n, j) => {
                        dealer2.push({ label: m.title.rendered, value: m.id, region: n, id: m.id, email: m.acf.email, additional_logic: m.acf.additional_logic_for_finishing_touches})
                    })
                }
            })
        });

        DB.dealers = [];
        dealer3 = [];
        await dealer3.push(...dealer1, ...dealer2);

        dealer3.map((m, i) => {
            dealer3[i].label = m.label.replace("&#8217;", "'");
            dealer3[i].label = m.label.replace("&#8211;", "–");
            dealer3[i].label = m.label.replace("&#038;", "&");
            dealer3[i].label = m.label.replace("&AMP;", "&");
            dealer3[i].label = m.label.replace("&amp;", "&");
        })
        
        if (query.has("reg_id")){
            DB.dealers.push([{ label: "", value: "", region: 0, id: 0, email: ""}])
            regions.map(m => {
                if (m.id === Number(query.get("reg_id"))){
                    const temp = dealer3.filter((f) => (f.region === m.id));
                    DB.dealers.push({
                        label: m.name,
                        id: m.id,
                        options: [...temp]
                    })
                }
            })
        } else if (query.has("id")){
            const temp = dealer3.filter((f) => (f.id === Number(query.get("id"))));
            DB.dealers.push(...temp)
        }else{
            DB.dealers.push({
                label: "Select a Dealer*",
                options: [{ label: "", value: "", region: 0, id: 0, email: ""}]
            },
            {
                label: "Global",
                options: [{ label: "Williams Jet Tenders Ltd", value: "Williams Jet Tenders Ltd", region: 0, id: 0, email: "sales@williamsjettenders.com", additional_logic: ""}]
            })
            regions.map(m => {
                const temp = dealer3.filter((f) => (f.region === m.id));
                DB.dealers.push({
                    label: m.name,
                    id: m.id,
                    options: [...temp]
                })
            })
        }

        DB.API_ready = true;
    }
    
    useEffect(() => {
        updateData();
    }, []);

    return (
        useRoutes(Route) 
    );
}

export default App;
